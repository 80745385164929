<template>
	<el-form ref="form" :model="form" label-width="220px" class="form" :rules="rules">
		<el-form label-width="230px" :model="form" ref="form" :rules="rules" :validate-on-rule-change="false">
			<el-form-item label="营业执照(副本)照片" prop="license">
				<el-upload class="avatar-uploader" :action="picUrl"
					v-loading.fullscreen.lock="loading"
					  element-loading-text="拼命加载中……"
					 :on-progress="changezz"
					  :before-upload="beforeAvatarUploadzz"
					 element-loading-background="rgba(250, 250, 250, 0.3)"
					:show-file-list="false" :data="fileInfo2" :on-success="handleAvatarSuccess" accept="image/jpeg,image/png">
					<img v-if="imageUrl" :src="imageUrl" :onerror="imgSrc" class="avatar">
					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				</el-upload>
				<div class="tishinews">
					小提示：上传图片只能是JPG/PNG格式且上传图片大小不能超过 5MB！
				</div>
				<div class="ts"></div>
				<div class="text">
					<ul class="textul">
						<li>要求：</li>
						<li>营业执照（副本）四边角需清晰，信息完整，不能遮挡/涂抹/修改</li>
						<li>营业执照（副本）如为复印件则必须加盖公章</li>
						<li>营业执照（副本）图片需清晰</li>
						<li>图片不可添加与备案信息无关的水印</li>
						<li>必须为彩色扫描件或数码照片</li>
						<li>必须为中国大陆工商局颁发</li>
						<li>副本必须在有效期内，且带有最新年检章(当前成立的公司可以无年检章)</li>
					</ul>
				</div>
			</el-form-item>
		<el-form-item label="公司名称" prop="company_name">
			<el-input v-model="form.company_name" />
		</el-form-item>
		<el-form-item label="统一社会信用代码或营业执照号" prop="license_code">
			<el-input v-model="form.license_code" />
		</el-form-item>
		<el-form-item>
			<el-button type="primary" @click="onSubmit">下一步</el-button>
		</el-form-item>
	</el-form>
</el-form>
</template>

<script>
	export default {
		data() {
			return {
				imgSrc: 'this.src="' + require('../../assets/img/mr.jpg') + '"',   //默认图片
				loading:false,
				fileInfo2:{
					type:"Member",
					zhengjian:"2"//营业执照
				},
				comname:'',
				comcode:'',
				form: {
					company_name: '',
					license_code: '',
					license: '',
				},
				imageUrl: '',
				rules: {
					company_name: [{
						required: true,
						message: '请输入公司名称'
					}],
					license_code: [{
						required: true,
						message: '请输入统一社会信用代码或营业执照号'
					}],
					license: [{
						required: true,
						message: '请上传营业执照（副本）'
					}]
				},
				picUrl:""
			}
		},
		created() {
			this.picUrl = this.$store.state.BASE_URL+'/uploadImgOne.html'
			if(this.$route.query.queryForm.license){
				this.form = this.$route.query.queryForm
				this.imageUrl = this.$route.query.queryForm.license ? this.$store.state.IMG_URL+'/'+this.$route.query.queryForm.license :''
			}
			
		},
		methods: {
			changezz(event, file, fileList){
				this.loading = true;
				
			},
			handleAvatarSuccess(res, file) {
				if (res.code == 200) {
					this.loading = false;
				   this.form.company_name = res.data.data.单位名称
				   this.form.license_code = res.data.data.社会信用代码
				   this.form.license = res.data.src
				   this.imageUrl = URL.createObjectURL(file.raw);
				   let comname = res.data.data.单位名称
				   let comcode = res.data.data.社会信用代码
				   if (!comname || !comcode) {
				    this.$message.error("图片不正确/不清晰,请重新上传~")
				   } else {
				    this.$message.success("上传成功")
				   }
				}else{
					this.loading = false;
					this.$message.error(res.msg)
				}
			},
			beforeAvatarUploadzz(file){
				const isJPG = file.type === 'image/jpeg';
				const isPNG = file.type === 'image/png';
				const isLt5M = file.size / 1024 / 1024 < 5;
				if (!isJPG && !isPNG) {
					this.$message.error('上传图片只能是 JPG/PNG 格式!');
				}
				if (!isLt5M) {
					this.$message.error('上传图片大小不能超过 5MB!');
				}
				return (isJPG || isPNG) && isLt5M;
			},
			onSubmit(data) {
				this.$refs.form.validate((valid) => {
					if (valid && this.form.company_name && this.form.license_code) {
						this.$emit('eventNext', this.form)
					} else {
						this.$message.error("请重新上传营业执照（副本）~")
						return false;
					}
				});
			}
		}
	}
</script>

<style>
	.tishinews{
		color: #666ee8;
	}
</style>
